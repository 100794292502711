import Footer from "../components/Footer";
import HeroPages from "../components/HeroPages";
import { Helmet } from "react-helmet";
import { db } from "../components/firebase.js";
import { getDocs, collection } from "firebase/firestore";
import { useState, useEffect } from "react";

function TestimonialsPage() {
  const [cars, setCars] = useState([]);
  const [expandedReviewIndex, setExpandedReviewIndex] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "review"));
        const data = querySnapshot.docs.map((doc) => doc.data());
        setCars(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const toggleExpand = (index) => {
    setExpandedReviewIndex(expandedReviewIndex === index ? null : index);
  };

  return (
    <>
      <Helmet>
        <title>Testimonials - Sleek Investments Car Rental</title>
        <meta
          name="description"
          content="Explore customer testimonials and reviews for Sleek Investments Car Rental. Explore Jamaica effortlessly with Sleek Investments Car Rental. Offering car rentals, guided tours, and airport transfers from Montego Bay Airport & St Mary Airports "
        />
        {/* Add more meta tags as needed */}
      </Helmet>

      <section className="testimonial-page">
        <HeroPages name="Testimonials" />

        <div className="models-div">
          {cars.map((car, index) => (
            <div
              key={index}
              style={{
                background:
                  "linear-gradient(325deg, #f7e9a0, #ffcc00, #d4af37, #b8860b)",
                backgroundSize: "200% 200%",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.3)",
                padding: "16px",
                height: "250px", // Set a fixed height
                overflow: "hidden", // Hide overflow
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between"
              }}
            >
              <div>
                <span className="quotes-icon">
                  <i className="fa-solid fa-quote-right"></i>
                </span>
                <p style={{ fontSize: "16px", maxHeight: "140px", overflow: "hidden" }}>
                  {expandedReviewIndex === index ? car.reviews : `${car.reviews.substring(0, 140)}...`}
                  <span
                    onClick={() => toggleExpand(index)}
                    style={{ color: "lightblue", cursor: "pointer", marginLeft: "5px" }}
                  >
                    {expandedReviewIndex === index ? "Show Less" : "Read More"}
                  </span>
                </p>
              </div>
              <div className="all-testimonials__box__name">
                <div className="all-testimonials__box__name__profile">
                  <span>
                    <h4 style={{ color: "white" }}>{car.name}</h4>
                  </span>
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className="book-banner">
          <div className="book-banner__overlay"></div>
          <div className="container">
            <div className="text-content">
              <h2>Book a car by getting in touch with us</h2>
              <span>
                <i className="fa-solid fa-phone"></i>
                <h3 style={{ color: "gold" }}> +1 (876)798-7720 </h3>
              </span>
            </div>
          </div>
        </div>
        <Footer />
      </section>
    </>
  );
}

export default TestimonialsPage;

