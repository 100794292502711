import Hero from "../components/Hero";
import BookCar from "../components/BookCar";
import React, { useState, useEffect } from 'react';
import PickCar from "../components/PickCar";
import Banner from "../components/Banner";
import Bannerz from "../components/clientbanner";
import ChooseUs from "../components/ChooseUs";
import Testimonials from "../components/Testimonials";
import Faq from "../components/Faq";
import Footer from "../components/Footer";
//import Models from "./Models";
import { Helmet } from "react-helmet";
import Options from "../components/Option";

function Home() {

  const [showDelayedComponents, setShowDelayedComponents] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowDelayedComponents(true);
    }, 2000);

    return () => clearTimeout(timer); // Cleanup the timer on component unmount
  }, []);



  return (
    <>
      <Helmet>
        <title>
        Home - Sleek Investments Car Rental
        </title>

        <meta
          name="description"
          content="Explore Jamaica effortlessly with Sleek Investments Car Rental. Offering car rentals, guided tours, and airport transfers from Montego Bay Airport & St Mary Airports. Located at 5 Windsor Rd, St Ann's Bay."
        />
        {/* Add more meta tags as needed */}
      </Helmet>

      <Hero />
      <PickCar />
      
      <Options/>
      <ChooseUs />

    
      
      <Bannerz/>

      <Testimonials />
      {showDelayedComponents && (
         <>

      <Banner />
      <Faq />
      <Footer />
      </>
    )}
      
    </>
  );
}

export default Home;
