import Footer from "../components/Footer";
import HeroPages from "../components/HeroPages";
import BookTour from "../components/BookTour";
import AboutMain from "../images/about/about-main.jpg";
import Box1 from "../images/about/icon1.png";
import Box2 from "../images/about/icon2.png";
import Box3 from "../images/about/icon3.png";
import BookTransfer from "../components/BookTransfer";
import { Helmet } from "react-helmet";
import PickTour from "../components/PickTour";

function Tours() {
  return (
    <>
      <Helmet>
        <title>Tours & Activities - Sleek Investments Car Rental</title>
        
        <meta
          name="description"
          content="Explore Jamaica’s top attractions with Sleek Investments Car Rental. From Dunn’s River Falls and Dolphin Cove to Glistening Waters and Mystic Mountain, our tours offer unforgettable activities like snorkeling, rafting, and parasailing. Discover the island’s beauty, from Bob Marley Museum in Lime Hall to a glass-bottom boat ride in St Ann's Bay."
        />
       
        {/* Add more meta tags as needed */}
      </Helmet>

      <section className="about-page">
        <HeroPages name="Tours & Activities" />
        <div >

<PickTour/>
         
        </div>
      </section>
      <div className="book-banner" style={{marginTop:'-100px'}}>
        <div className="book-banner__overlay"></div>
        <div className="container">
          <div className="text-content">
            <h2>For more information, we can be contact at</h2>
            <span>
              <i className="fa-solid fa-phone"></i>
              <h3 style={{color:'gold'}}> +1 (876)798-7720</h3> 
            </span>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Tours;
