import { useState } from "react";
import Card1 from "../images/about/pay2.png";
import Card2 from "../images/about/pay1.png";

function Faq() {
  const [activeQ, setActiveQ] = useState("q1");

  const openQ = (id) => {
    setActiveQ(activeQ === id ? "" : id);
  };

  const getClassAnswer = (id) => {
    return activeQ === id ? "active-answer" : "";
  };

  const getClassQuestion = (id) => {
    return activeQ === id ? "active-question" : "";
  };

  return (
    <>
      <section className="faq-section" style={{marginTop:'-150px'}}>
        <div className="container" >
          <div className="faq-content">
            <div className="faq-content__title">
              <h5>FAQ</h5>
              <h2>Frequently Asked Questions</h2>
              <p>
                Frequently Asked Questions About the Car Rental Booking Process
                on Our Website: Answers to Common Concerns and Inquiries.
              </p>
            </div>

            <div className="all-questions">
              <div className="faq-box">
                <div
                  style={{
                    background: 'linear-gradient(195deg, #f7e9a0, #ffcc00, #d4af37, #b8860b)',
                    backgroundSize: '200% 200%',
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)',
                    padding: '20px',
                   // borderRadius: '10px',
                    color: 'white',
                    textAlign: 'center',
                    animation: 'shine 3s infinite linear',
                  }}
                  id="q1"
                  onClick={() => openQ("q1")}
                  className={`faq-box__question  ${getClassQuestion("q1")}`}
                >
                  <p>1. Do you provide airport transfers and tours?</p>
                  <i className="fa-solid fa-angle-down"></i>
                </div>
                <div
                  id="q1"
                  onClick={() => openQ("q1")}
                  className={`faq-box__answer ${getClassAnswer("q1")}`}
                >
                  Yes, we provide airport transfers and tours. To reserve fill in your booking on website or call/whatsapp us on our provided number.
                </div>
              </div>
              <div className="faq-box">
                <div
                  style={{
                    background: 'linear-gradient(135deg, #f7e9a0, #ffcc00, #d4af37, #b8860b)',
          backgroundSize: '200% 200%',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)',
          padding: '20px',
         // borderRadius: '10px',
          color: 'white',
          textAlign: 'center',
          animation: 'shine 3s infinite linear',
                  }}
                  id="q2"
                  onClick={() => openQ("q2")}
                  className={`faq-box__question ${getClassQuestion("q2")}`}
                >
                  <p>2. How can I contact you?</p>
                  <i className="fa-solid fa-angle-down"></i>
                </div>
                <div
                  id="q2"
                  onClick={() => openQ("q2")}
                  className={`faq-box__answer ${getClassAnswer("q2")}`}
                >
                  We can be contacted via whatsapp, email or even a txt
                  message. Discover the perfect car rental deals in Jamaica with us
                </div>
              </div>
              <div className="faq-box">
                <div
                  style={{
                    background: 'linear-gradient(235deg, #f7e9a0, #ffcc00, #d4af37, #b8860b)',
                    backgroundSize: '200% 200%',
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)',
                    padding: '20px',
                   // borderRadius: '10px',
                    color: 'white',
                    textAlign: 'center',
                    animation: 'shine 3s infinite linear',
                  }}
                  id="q3"
                  onClick={() => openQ("q3")}
                  className={`faq-box__question ${getClassQuestion("q3")}`}
                >
                  <p>3. When was this company established?</p>
                  <i className="fa-solid fa-angle-down"></i>
                </div>
                <div
                  id="q3"
                  onClick={() => openQ("q3")}
                  className={`faq-box__answer ${getClassAnswer("q3")}`}
                >
               Sleek Investments Car Rental was established in 2021 and is since servicing
                  customers in St Ann's Bay, Jamaica with professionalism.
                </div>
              </div>
            

           

           



            </div>
          </div>

          <div style={{ marginTop: "35px" }}>
            <h2 style={{ textAlign: "center", fontWeight: "bold" }}>
              We Accept All Payment Methods
            </h2>
            <div className="row" style={{marginTop:'7px'}}>
              <div className="col-6">
                <img src={Card1} style={{width:'90%', height:'100%'}} />
              </div>

              <div className="col-6">
                <img src={Card2}  style={{width:'90%', height:'100%'}} />
              </div>
            </div>
          </div>


        </div>
      </section>
    </>
  );
}

export default Faq;
