import Footer from "../components/Footer";
import HeroPages from "../components/HeroPages";
import PlanTrip from "../components/PlanTrip";
import AboutMain from "../images/about/about-main.jpg";
import Box1 from "../images/about/icon1.png";
import Box2 from "../images/about/icon2.png";
import Box3 from "../images/about/icon3.png";
import { Helmet } from "react-helmet";

function About() {
  return (
    <>
      <Helmet>
        <title>About Us - Sleek Investments Car Rental</title>

        <meta
          name="description"
          content="Explore Jamaica effortlessly with Sleek Investments Car Rental. Best car rental in St Ann's bay, guided tours, & Montego Bay Airport Transfer & St Mary Airports Transfer. Located at 5 Windsor Rd, St Ann's Bay."
        />
        {/* Add more meta tags as needed */}
      </Helmet>

      <section className="about-page">
        <HeroPages name="About" />
        <div className="container">
          <div className="about-main">
            <img
              className="about-main__img"
              src={'https://images.pexels.com/photos/7144174/pexels-photo-7144174.jpeg?auto=compress&cs=tinysrgb&w=600'}
              alt="car-rental dealership"
            />
            <div className="about-main__text">
              <h3 style={{marginBottom:'30px', fontSize:'32px', textAlign:'center'}}>About Company</h3>
              <h3>Mission Statement
              </h3>

              <h4 style={{marginBottom:'15px', fontStyle:'italic'}} > "Where every journey is a unique story, and every traveler is a valued companion."
              </h4 >

              <h3>Vision Statement
              </h3>

              <h4 style={{fontStyle:'italic'}}>“To revolutionize the travel experience through cutting-edge technology, while envisioning a future where every journey is a personalized, immersive, and unforgettable adventure.”
              </h4>


              <p>
              Sleek investments ltd  was established in 2021, our company is located in the Centerpiece of Jamaica at 5 Windsor Road, St. Ann’s Bay. We offer services such as car rental, airport pick up/drop offs and tours/Activities. At Sleek, we prioritize flexibility and comfort, offering a wide range of models to suit every taste and need, from sleek sedans to spacious SUVs. Our extensive fleet features sleek and comfortable vehicles that are perfect for exploring new destinations off the beaten path to uncover hidden gems and local favorites.

              </p>
             
            </div>
          </div>

          {/*   <PlanTrip /> */}
         
        </div>
      </section>
      <div className="book-banner">
        <div className="book-banner__overlay"></div>
        <div className="container">
          <div className="text-content">
            <h2>Book a car by getting in touch with us</h2>
            <span>
              <i className="fa-solid fa-phone"></i>
              <h3 style={{color:'gold'}}>+1 (876)798-7720</h3> 
            </span>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default About;
