import { useState, useEffect } from "react";

import { db } from "./firebase.js";
import { getDocs, collection } from "firebase/firestore";
import BookTour from "./BookTour.jsx";



function PickTour() {
  const [cars, setCars] = useState([]);

  const [isExpanded, setIsExpanded] = useState(false);

 

 

  useEffect(() => {
    const fetchData = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "tours"));
        const data = querySnapshot.docs.map((doc) => doc.data());
        setCars(data); // Update this line
        // setCarTypes(data.map((car) => car.name));
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const [debouncedReserveNow, setDebouncedReserveNow] = useState(null);

  const handleReserveNow = () => {
    // Your logic for handling Reserve Now
    window.location.href = "/tours#tourbooking-section"; // Example: change URL
  };

  const debounce = (func, delay) => {
    if (debouncedReserveNow) {
      clearTimeout(debouncedReserveNow);
    }

    const timeout = setTimeout(() => {
      func();
      setDebouncedReserveNow(null);
    }, delay);

    setDebouncedReserveNow(timeout);
  };


  const [selectedCar, setSelectedCar] = useState(null);

  const handleClick = (car) => {
   // setSelectedCar('');
    setSelectedCar(car.name);
    debounce(handleReserveNow, 400);

  };

  const [expandedCardIndex, setExpandedCardIndex] = useState(null);

  const toggleExpand = (index) => {
    setExpandedCardIndex(expandedCardIndex === index ? null : index);
  };

  return (
    <section id="pick-section" className="pick-section" style={{marginTop:'-90px'}} >
      <div className="container">
        <div className="pick-container__title">
          <h2>Tours & Activities</h2>
          <p>
          We offer a variety of tours and activities so our clients can truely enjoy their visit to Jamaica.
          </p>
        </div>

        <div className="models-div">
        {cars.map((car, index) => (
            <div
              key={car.id}
              className="models-div__box"
              style={{
               // backgroundColor: "#f9f9f9",
               backgroundColor: "black", 
                //border: "2px solid #ddd",
               //  border: "2px solid gold",
                borderRadius: "8px",
                transition: "transform 0.3s ease",
                boxShadow: "0px 0px 5px 7px #b8860b, 0px 3px 3px 4px #b8860b",
                position: "relative",
                overflow: "hidden",
                cursor: "pointer", 
              }}
              onMouseOver={(e) =>
                (e.currentTarget.style.transform = "scale(1.05)")
              }
              onMouseOut={(e) => (e.currentTarget.style.transform = "scale(1)")}
            >
              <div className="models-div__box__img">
                <img
                  src={car.img}
                  alt="car_img"
                  style={{
                    height: "230px",
                    width: "100%",
                    backgroundColor:'white',
                    // objectFit: "cover",
                    borderTopLeftRadius: "8px",
                    borderTopRightRadius: "8px",
                    "@media(max-width: 500px)": {
                      // Media query for screens less than 500px width
                      height: "auto", // Allow the height to adjust according to the aspect ratio
                      width: "100%", // Take full width of the container
                    },
                  }}
                />
                <div
                  className="models-div__box__img-overlay"
                  style={{
                    position: "absolute",
                    top: "10px",
                    left: "10px",
                    backgroundColor: "rgba(0, 0, 0, 0.7)",
                    padding: "5px 10px",
                    borderRadius: "5px",
                    color: "#fff",
                    fontSize: "16px",
                    fontWeight: "bold", color:'white'
                  }}
                >
                  {car.name}
                </div>
              </div>
              <div
                className="models-div__box__descr"
                style={{ padding: "15px" }}
              >
                <div
                  className="models-div__box__descr__price"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <h4
                    style={{
                      margin: "5px 10px 5px 0",
                      color: "#333",
                      fontSize: "24px", color:'white'
                    }}
                  >
                     {car.price}
                  </h4>

                  <p style={{ margin: "0", fontSize: "14px", color: "silver" }}>
                    per {car.fuel}
                  </p>

                </div>
               

                <div className="models-div__box__descr">
            <div
              className="models-div__box__descr__details"
              style={{
                height: expandedCardIndex === index ? "auto" : "80px", // Set a standard height
                overflow: "hidden",
                transition: "height 0.3s ease", marginBottom:'10px'
              }}
            >
              <p style={{ fontSize: "12px", margin: "2px", color: "white" }}>
                {expandedCardIndex === index
                  ? car.air
                  : `${car.air.substring(0, 100)}...`}
                <span
                  onClick={() => toggleExpand(index)}
                  style={{
                    color: "lightblue",
                    cursor: "pointer",
                    marginLeft: "5px",
                  }}
                >
                  {expandedCardIndex === index ? "Show Less" : "Read More"}
                </span>
              </p>

            </div>
            </div>
               
                <button
                  className="models-div__box__descr__btn"
                  style={{
                    height: "40px",
                    width: "100%",
                    background: 'linear-gradient(135deg, #f7e9a0, #ffcc00, #d4af37, #B8860B)',
                    backgroundSize: '200% 200%',
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)',
                   // padding: '20px',
                   // borderRadius: '10px',
                    color: 'white',
                   // textAlign: 'center',
                    animation: 'shine 2s infinite linear',
                    border: "none",
                    borderRadius: "5px",
                    cursor: "pointer",
                   // color: "white",
                    fontWeight: "bold",
                    marginTop: "10px",
                    transition: "background-color 0.3s ease",
                    fontSize: "15px",
                  }}
                  onClick={() => handleClick(car)}
                  onMouseOver={(e) =>
                    (e.currentTarget.style.backgroundColor = "#20a4f3")
                  }
                  onMouseOut={(e) =>
                    (e.currentTarget.style.backgroundColor = "#182b3a")
                  }
                >
                  Book Now
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>

      { <BookTour carName={selectedCar} />}

    </section>
  );
}

export default PickTour;
