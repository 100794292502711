import { Link } from "react-router-dom";

function HeroPages({ name }) {
  return (
    <>
      <section className="hero-pages" style={{marginTop:'-50px', }}>
        <div className="hero-pages__overlay" style={{backgroundColor: 'rgba(0, 0, 0, 0.5)'}} ></div>
        <div className="container" >
          <div className="hero-pages__text">
            <h3 style={{color:'white'}}>{name}</h3>
            <p style={{color:'white'}}>
              <Link to="/" style={{color:'silver'}}>Home</Link> / {name}
            </p>
          </div>
        </div>
      </section>
    </>
  );
}

export default HeroPages;
