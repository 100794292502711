import { useState, useEffect } from "react";
import CarBox from "./CarBox";
import { Link } from "react-router-dom";
import { db } from "../components/firebase.js";
import { getDocs, collection } from "firebase/firestore";
import BookCar from "./BookCar.jsx";


function PickCar() {
  const [cars, setCars] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "carsdata"));
        const data = querySnapshot.docs.map((doc) => doc.data());
        setCars(data); // Update this line
        // setCarTypes(data.map((car) => car.name));
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const [debouncedReserveNow, setDebouncedReserveNow] = useState(null);

  const handleReserveNow = () => {
    // Your logic for handling Reserve Now
    window.location.href = "#booking-section"; // Example: change URL
  };

  const debounce = (func, delay) => {
    if (debouncedReserveNow) {
      clearTimeout(debouncedReserveNow);
    }

    const timeout = setTimeout(() => {
      func();
      setDebouncedReserveNow(null);
    }, delay);

    setDebouncedReserveNow(timeout);
  };


  const [selectedCar, setSelectedCar] = useState(null);

  const handleClick = (car) => {
   // setSelectedCar('');
    setSelectedCar(car.name);
    debounce(handleReserveNow, 400);
  };

  return (
    <section id="pick-section" className="pick-section" style={{marginTop:'-100px'}} >
      <div className="container">
        <div className="pick-container__title">
          <h2>Rental Fleet</h2>
          <p>
          We offer a range of vehicles for all your car rental needs. We have the perfect car to meet your needs.
          </p>
        </div>

        <div className="models-div">
          {cars.map((car) => (
            <div
              key={car.id}
              className="models-div__box"
              style={{
               // backgroundColor: "#f9f9f9",
               backgroundColor: "black", 
                //border: "2px solid #ddd",
               //  border: "2px solid gold",
                borderRadius: "8px",
                transition: "transform 0.3s ease",
                boxShadow: "0px 0px 5px 7px #b8860b, 0px 3px 3px 4px #b8860b",
                position: "relative",
                overflow: "hidden",
                cursor: "pointer",
              }}
              onMouseOver={(e) =>
                (e.currentTarget.style.transform = "scale(1.05)")
              }
              onMouseOut={(e) => (e.currentTarget.style.transform = "scale(1)")}
            >
              <div className="models-div__box__img">
                <img
                  src={car.img}
                  alt="car_img"
                  style={{
                    height: "230px",
                    width: "100%",
                    backgroundColor:'white',
                    // objectFit: "cover",
                    borderTopLeftRadius: "8px",
                    borderTopRightRadius: "8px",
                    "@media(max-width: 500px)": {
                      // Media query for screens less than 500px width
                      height: "auto", // Allow the height to adjust according to the aspect ratio
                      width: "100%", // Take full width of the container
                    },
                  }}
                />
                <div
                  className="models-div__box__img-overlay"
                  style={{
                    position: "absolute",
                    top: "10px",
                    left: "10px",
                    backgroundColor: "rgba(0, 0, 0, 0.7)",
                    padding: "5px 10px",
                    borderRadius: "5px",
                    color: "#fff",
                    fontSize: "16px",
                    fontWeight: "bold", color:'white'
                  }}
                >
                  {car.name}
                </div>
              </div>
              <div
                className="models-div__box__descr"
                style={{ padding: "15px" }}
              >
                <div
                  className="models-div__box__descr__price"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <h4
                    style={{
                      margin: "5px 10px 5px 0",
                      color: "#333",
                      fontSize: "24px", color:'white'
                    }}
                  >
                    J$ {car.price}
                  </h4>
                  <p style={{ margin: "0", fontSize: "14px", color: "silver" }}>
                    per day
                  </p>
                </div>
                <div className="models-div__box__descr__details">
                  <span style={{ fontSize: "12px", margin: "2px", color:'white' }}>
                    &#x2022; {car.transmission}
                  </span>
                  <span style={{ fontSize: "12px", margin: "2px", color:'white' }}>
                    &#x2022; {car.fuel}
                  </span>
                  <span style={{ fontSize: "12px", margin: "2px" ,color:'white'}}>
                    &#x2022; {car.air}
                  </span>
                </div>
                <button
                  className="models-div__box__descr__btn"
                  style={{
                    height: "40px",
                    width: "100%",
                    background: 'linear-gradient(135deg, #f7e9a0, #ffcc00, #d4af37, #B8860B)',
                    backgroundSize: '200% 200%',
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)',
                   // padding: '20px',
                   // borderRadius: '10px',
                    color: 'white',
                   // textAlign: 'center',
                    animation: 'shine 2s infinite linear',
                    border: "none",
                    borderRadius: "5px",
                    cursor: "pointer",
                   // color: "white",
                    fontWeight: "bold",
                    marginTop: "10px",
                    transition: "background-color 0.3s ease",
                    fontSize: "15px",
                  }}
                  onClick={() => handleClick(car)}
                  onMouseOver={(e) =>
                    (e.currentTarget.style.backgroundColor = "#20a4f3")
                  }
                  onMouseOut={(e) =>
                    (e.currentTarget.style.backgroundColor = "#182b3a")
                  }
                >
                  Reserve Now
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>

      { <BookCar carName={selectedCar} />}

    </section>
  );
}

export default PickCar;
