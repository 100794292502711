import Img2 from "../images/testimonials/pfp1.jpg";
import Img3 from "../images/testimonials/pfp2.jpg";
import { db } from "./firebase.js";
import { getDocs, collection } from "firebase/firestore";
import { useState, useEffect } from "react";

function Testimonials() {

  const [cars, setCars] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "review"));
        const data = querySnapshot.docs.map((doc) => doc.data());
        setCars(data); // Update this line
        // setCarTypes(data.map((car) => car.name));
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);


  return (
    <>
      <section className="testimonials-section">
        <div className="container">
          <div className="testimonials-content">
            <div className="testimonials-content__title">
              <h4>Reviewed by People</h4>
              <h2>Client's Testimonials</h2>
              <p>
              Discover the positive impact through client testimonials. Our reliable and affordable car rentals in Jamaica have earned rave reviews. Join satisfied customers for a seamless and memorable travel experience. Choose Us for your next journey.
              </p>
            </div>

            <div className="all-testimonials">
              <div className="all-testimonials__box" style={{background: 'linear-gradient(325deg, #f7e9a0, #ffcc00, #d4af37, #b8860b)',
                backgroundSize: '200% 200%',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)'}} >
                <span className="quotes-icon">
                  <i className="fa-solid fa-quote-right"></i>
                </span>
                <p>
                "Vehicles are comfortable, always clean and well maintained. Top tier customer service and top tier product."
                </p>
                <div className="all-testimonials__box__name">
                  <div className="all-testimonials__box__name__profile">
                  {/* <img src={Img2} alt="user_img" /> */}  
                    <span>
                    <h4 style={{color:'white'}}>Milton Notice</h4>
                      
                    </span>
                  </div>
                </div>
              </div>

              <div className="all-testimonials__box box-2" style={{background: 'linear-gradient(325deg, #f7e9a0, #ffcc00, #d4af37, #b8860b)',
                backgroundSize: '200% 200%',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)'}}>
                <span className="quotes-icon">
                  <i className="fa-solid fa-quote-right"></i>
                </span>
                <p>
                "The process was smooth and fast to get the car even though I made the request last minute, a fault of mine, they made it possible for me to get a car right away.
                "
                </p>
                <div className="all-testimonials__box__name">
                  <div className="all-testimonials__box__name__profile">
                  
                    <span>
                    <h4 style={{color:'white'}}>Zalika Knight</h4>
                     
                    </span>
                  </div>
                </div>
              </div>
            </div>


          </div>

<h3 style={{textAlign:'center', marginTop:'15px'}}>Read more reviews <a 
href="/testimonials"

>here</a>  </h3>

        </div>
      </section>
    </>
  );
}

export default Testimonials;
