import React, { useState, useEffect } from "react";

function Bannerz() {
  

  const images = [

    {
      url: "https://www.sandals.com/blog/content/images/2020/01/White-River-Rafting-Jamaica.jpg",

    },

    {
      url: "https://www.sandals.com/blog/content/images/2020/01/Frenchman-Cove-Jamaica.jpg",
    },
    {
      url: "https://cdn1.matadornetwork.com/blogs/1/2021/03/Guanacaste-beach-in-Costa-Rica.jpg",
    },


    {
      url: "https://www.sandals.com/blog/content/images/2020/01/Blue-Hole-Lagoon-Jamaica.jpg",
    },
    
    {
      url: "https://assets.simpleviewinc.com/simpleview/image/upload/c_fill,f_avif,h_839,q_65,w_1920/v1/clients/jamaica-redesign/203_JTB_Romance_MontegoBay_Martha_Brae_9548a3e9-b5fb-4bd0-b9a1-33106b2197aa.png",
    },


   
    
    
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) =>
        prevIndex === images.length - 1 ? 0 : prevIndex + 1
      );
    }, 2000);

    return () => clearInterval(interval);
  }, [currentIndex, images.length]);

  const bgImageStyle = {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  };

  return (
    <>
      <section
        className="banner-section"
        style={{
          //backgroundColor: '#00b712',
         // backgroundImage: 'linear-gradient(95deg, #00690a 0%, #3a9e0e 79%)'
         position: 'relative',
         height:'600px'
        }}
      >
         <img alt="Background Shape" style={bgImageStyle}  src={images[currentIndex].url}
            />
      

  
    </section>
  </>
);
}

export default Bannerz;