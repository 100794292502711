

import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';


const firebaseConfig = {

    apiKey: "AIzaSyBH67AIZ2kWzq6x-JvcW83ARVp889SKBtg",

    authDomain: "sleek-car-rental.firebaseapp.com",
  
    projectId: "sleek-car-rental",
  
    storageBucket: "sleek-car-rental.appspot.com",
  
    messagingSenderId: "983965434217",
  
    appId: "1:983965434217:web:fb0b43253289b55f5dfdf8"
  

  
  
  };
  
  
  

  const app = initializeApp(firebaseConfig);
  const db = getFirestore(app);
  const storage = getStorage(app);
  
  export { db, storage };