import { useEffect, useState } from "react";
import { db, storage } from "./firebase.js";
import BgShape from "../images/hero/Airportpic.jpg";
import {
  doc,
  getDocs,
  setDoc,
  onSnapshot,
  collection,
  addDoc,
} from "firebase/firestore";
import PhoneInput from "react-phone-number-input";
import "./numberinput.css"; // Import your custom styles
import emailjs from "@emailjs/browser";



function BookTransfer({ carName }) {  
  const [carTypes, setCarTypes] = useState([]);
  const [selectedCar, setSelectedCar] = useState(carName);
  const [carImgUrl, setCarImgUrl] = useState("");
  const [carData, setCarData] = useState([]);



  useEffect(() => {
  setCarImgUrl('')
}, [carName]);


  useEffect(() => {
  
    console.log(carName)
  setSelectedCar(carName)
  setCarData('')
      
      const handleSelectChange = async () => {
        // Find the selected car in the data and set its image URL
        const selectedCarData = carData.find((car) => car.name === carName);
        if (selectedCarData) {
          setCarImgUrl(selectedCarData.img);
        } else {
          // If the data is not in the state, fetch it from Firestore
          try {
            const querySnapshot = await getDocs(collection(db, "carsdata"));
            const data = querySnapshot.docs.map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }));

            setCarData(data);
            const newSelectedCarData = data.find(
              (car) => car.name === carName
            );
            if (newSelectedCarData) {
              setCarImgUrl(newSelectedCarData.img);
            }
          } catch (error) {
            console.error("Error fetching data:", error);
          }
        }
      };
  handleSelectChange()
    
  console.log('Car Changed')
  
}, [carName]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "carsdata"));
        const data = querySnapshot.docs.map((doc) => doc.data());
        setCarData(data); // Update this line
        setCarTypes(data.map((car) => car.name));
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [carName]);





  const handleSelectChange = async (event) => {
    const selectedCarName = event.target.value;
    setSelectedCar(selectedCarName);

    // Find the selected car in the data and set its image URL
    const selectedCarData = carData.find((car) => car.name === selectedCarName);
    if (selectedCarData) {
      setCarImgUrl(selectedCarData.img);
    } else {
      // If the data is not in the state, fetch it from Firestore
      try {
        const querySnapshot = await getDocs(collection(db, "carsdata"));
        const data = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setCarData(data);
        const newSelectedCarData = data.find(
          (car) => car.name === selectedCarName
        );
        if (newSelectedCarData) {
          setCarImgUrl(newSelectedCarData.img);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };

  //////////////

  const [modal, setModal] = useState(false); //  class - active-modal
  // booking car
  const [carType, setCarType] = useState("");
  const [pickUp, setPickUp] = useState("");
  const [dropOff, setDropOff] = useState("");
  const [pickTime, setPickTime] = useState("");
  const [dropTime, setDropTime] = useState("");
  const [carImg, setCarImg] = useState("");
  // modal infos
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [age, setAge] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [pickingTime, setPickingTime] = useState("");
  const [droppingTime, setDroppingTime] = useState("");
  const [q1, setQ1] = useState("");
  const [q2, setQ2] = useState("");
  const [q3, setQ3] = useState("");
  const [q4, setQ4] = useState("");
  

  // taking value of modal inputs
  const handleName = (e) => {
    setName(e.target.value);
  };

  const handleLastName = (e) => {
    setLastName(e.target.value);
  };

  const handlePhone = (e) => {
    setPhone(e.target.value);
  };

  const handleAge = (e) => {
    setAge(e.target.value);
  };

  const handleEmail = (e) => {
    setEmail(e.target.value);
  };

   const handleAddress = (e) => {
     setAddress(e.target.value);
    };

  const handleQ1 = (e) => {
    setQ1(e.target.value);
  };

  const handleQ2 = (e) => {
    setQ2(e.target.value);
  };

  const handleQ3 = (e) => {
    setQ3(e.target.value);
  };

  const handleQ4 = (e) => {
    setQ4(e.target.value);
  };

  // open modal when all inputs are fulfilled
  const openModal = (e) => {
    e.preventDefault();
    const errorMsg = document.querySelector(".error-message");

    if (
      !pickUp ||
      !dropOff ||
      !pickTime ||
      !dropTime ||
      !droppingTime ||
      !selectedCar ||
      !pickingTime
    ) {
      errorMsg.style.display = "flex";
    } else {
      setModal(!modal);
      const modalDiv = document.querySelector(".booking-modal");
      modalDiv.scroll(0, 0);
      errorMsg.style.display = "none";
    }
  };

  // disable page scroll when modal is displayed
  useEffect(() => {
    if (modal === true) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [modal]);

  // confirm modal booking

  const handleTimeChange = (e) => {
    setPickingTime(e.target.value);
  };

  const handleTimeChange2 = (e) => {
    setDroppingTime(e.target.value);
  };

  const convertTo12HourFormat = (timeString) => {
    const [hour, minute] = timeString.split(":");
    const parsedHour = parseInt(hour, 10);
    const ampm = parsedHour >= 12 ? "PM" : "AM";
    const formattedHour = parsedHour % 12 === 0 ? 12 : parsedHour % 12;
    return `${formattedHour}:${minute} ${ampm}`;
  };


  const [images, setImages] = useState([]); // State for selected images
  const [imageURLs, setImageURLs] = useState([]); // State for image URLs

  // Function to handle image selection
  const handleImageChange = (e) => {
    const selectedFiles = Array.from(e.target.files).slice(0, 2); // Limit to 2 images
    setImages(selectedFiles);

    // Create image URLs for preview
    const imageURLs = selectedFiles.map(file => URL.createObjectURL(file));
    setImageURLs(imageURLs);
  };

  // Function to upload images and return their download URLs
  const uploadImages = async () => {
    const imageUploadPromises = images.map(async (image) => {
      const storageRef = storage.ref(`images/${image.name}`);
      await storageRef.put(image);
      return await storageRef.getDownloadURL();
    });

    return await Promise.all(imageUploadPromises);
  };

  // Function to handle form submission and Firestore upload
  const postBooking = async () => {
    try {
      if (!name || !lastName || !age || !phone || !email || !city
        || !q1 || !q2 || !q3 || !q4 
      ) {
        window.alert("Error: Fill All Fields!");
        return;
      }

      if (!images) {
        window.alert("Error: Also attach driving license images, front & back");
        return;
      }
  
      const formattedTime = convertTo12HourFormat(pickingTime);
      const formattedTime1 = convertTo12HourFormat(droppingTime);
  
      // Upload images and get their download URLs
      const imageDownloadURLs = await uploadImages();
  
      // Save booking data to Firestore
      await db.collection("bookingsdata").add({
        carname: selectedCar,
        pickup: pickUp,
        dropoff: dropOff,
        picktime: pickTime,
        droptime: dropTime,
        pickingtime: formattedTime,
        droppingtime: formattedTime1,
        name: name,
        gender: lastName,
        phone: phone,
        age: age,
        email: email,
        city: city,
        images: imageDownloadURLs, // Store image URLs in Firestore
      });
  
      // Log a message to the console if the document was added successfully
      console.log("Booking Details Posted!");
  
      // Show an alert to the user
      window.alert("Your booking is saved!");
  
      // Close the modal
      setModal(false);
  
      // Display the "booking done" message
      const doneMsg = document.querySelector(".booking-done");
      doneMsg.style.display = "flex";
  
      // Clear the form fields
      setPickUp("");
      setDropOff("");
      setPickTime("");
      setDropTime("");
      setCarType("");
      setName("");
      setLastName("");
      setPhone("");
      setAge("");
      setEmail("");
      setCity("");
      setPickingTime("");
      setDroppingTime("");
     
    } catch (error) {
      // Log any errors to the console
      console.error(error);
    }
  };

  const confirmBooking = (e) => {
    e.preventDefault();
    postBooking();
  };

  // taking value of booking inputs
  const handleCar = (e) => {
    const selectedCarName = e.target.value;
    setCarType(selectedCarName);

    // Find the selected car in the data and set its image URL
    const selectedCarData = carData.find((car) => car.name === selectedCarName);
    if (selectedCarData) {
      setCarImg(selectedCarData.img);
    }
  };

  const handlePick = (e) => {
    setPickUp(e.target.value);
  };

  const handleDrop = (e) => {
    setDropOff(e.target.value);
  };

  const handlePickTime = (e) => {
    setPickTime(e.target.value);
  };

  const handleDropTime = (e) => {
    setDropTime(e.target.value);
  };

  const formattedTimes = convertTo12HourFormat(pickingTime);
  const formattedTimes1 = convertTo12HourFormat(droppingTime);
  // hide message
  const hideMessage = () => {
    const doneMsg = document.querySelector(".booking-done");
    doneMsg.style.display = "none";
  };

  const bgImageStyle = {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
  };


  const sendEmail = () => {

    if (
      !pickUp ||
      !dropOff ||
      !pickTime ||
      !q1 ||
      !q2 ||
      !email ||
      !pickingTime
    ) {
      
      window.alert("Error: Please Fill All Fields! ");
    } else {


    const formattedTime = convertTo12HourFormat(pickingTime);
   // const formattedTime1 = convertTo12HourFormat(droppingTime);

    const templateParams = {

      type: 'Airport Transfer',
      tourtype:'nill',
      email: email,
      name: q2,
      peopleno: q1,
      pickup: pickUp,
      dropoff: dropOff,
      pdate: pickTime,
      ptime: formattedTime,
     

    };
  
    emailjs
      .send('service_4hq7nj6', 'template_2evjksn', templateParams, 'Nst7XlaMXOHkmgyS1')
      .then(
        (response) => {
          console.log('Message sent successfully:', response.status, response.text);
        },
        (error) => {
          console.error('Failed to send message:', error.text);
        }
      );


      window.alert("We have recieved your inquiry. You will get a response on your email soon.");
    }
  };



  return (
    <>
      <section
        id="booking-section"
        className="book-section"
        style={{ position: "relative" }}
      >
        {/* overlay */}
        <img src={BgShape} alt="Background Shape" style={bgImageStyle} />
        <div
          onClick={openModal}
          className={`modal-overlay ${modal ? "active-modal" : ""}`}
        ></div>

       

        <div className="container">
          <div className="book-content">
            <div
              className="book-content__box"
              style={{
                backgroundColor: "rgba(0, 0, 0, 0.45)", // 20% opacity for the background color
                // backgroundImage: 'linear-gradient(315deg, rgba(0, 153, 14, 0.2) 0%, rgba(77, 212, 18, 0.2) 74%)', // 20% opacity for the gradient
              }}
            >
              <h2 style={{ color: "yellow", fontWeight: "bold" }}>
                Book Airport Transfer{" "}
              </h2>
              <p className="error-message">
                All fields required! <i className="fa-solid fa-xmark"></i>
              </p>
              <p className="booking-done">
                Your booking is saved! Please do re-confirm it at over call
                before arrival.{" "}
                <i onClick={hideMessage} className="fa-solid fa-xmark"></i>
              </p>
              <form className="box-form">

                <div className="box-form__car-type">
                  <label style={{ color: "yellow" }}>
                    <i className="fa-solid fa-car"></i> &nbsp; Number Of People <b>*</b>
                  </label>

                  <select value={q1} onChange={handleQ1} style={{color:'#444443'}}  >
                    <option>Choose number of people</option>
                    <option>1</option>
                    <option>2</option>
                    <option>3</option>
                    <option>4</option>
                    <option>5</option>
                    <option>6</option>
                    <option>7</option>
                    <option>8</option>
                    <option>9</option>
                    <option>10</option>
                    <option>11</option>
                    <option>12</option>
                    <option>13</option>
                    <option>14</option>
                    <option>15</option>
                   
                  </select>

                </div>

                <div className="box-form__car-type">
                  <label style={{ color: "yellow" }}>
                    <i className="fa-solid fa-location-dot"></i> Pick-up{" "}
                    <b>*</b>
                  </label>
                  <select
                    value={pickUp}
                    onChange={handlePick}
                    style={{ color: "#444443" }}
                  >
                    <option>Select pick-up location</option>
                    <option>Montego Bay (Sangster Airport)</option>
                    <option>St. Mary (Ian Fleming Airport)</option>
                    <option>Kingston (Norman Manley Airport )</option>
                  </select>
                 
                </div>
                <div className="box-form__car-type">
                  <label style={{ color: "yellow" }}>
                    <i className="fa-solid fa-location-dot"></i> Drop-off{" "}
                    <b>*</b>
                  </label>
                  <input   value={dropOff}
                    onChange={handleDrop}
                    style={{ color: "#444443", height:'43px', paddingLeft:'10px' , fontSize:'14px' }}
                     placeholder="Enter Drop-off Location"
                    >
                  </input>      
                </div>

                <div className="box-form__car-type">
                  <label style={{ color: "yellow" }}>
                    <i className="fa-solid fa-location-dot"></i> Your Name{" "}
                    <b>*</b>
                  </label>
                  <input  value={q2}
                    onChange={handleQ2}
                    style={{ color: "#444443", height:'43px', paddingLeft:'10px' , fontSize:'14px' }}
                     placeholder="Enter Your Name"
                    >
                  </input>
                 
                </div>

                <div className="box-form__car-time">
                  <label htmlFor="picktime" style={{ color: "yellow", }}>
                    <i className="fa-regular fa-calendar-days "></i>
                    Pick-up Date <b>*</b>
                  </label>
                  <input style={{height:'43px'}}
                    id="picktime"
                    value={pickTime}
                    onChange={handlePickTime}
                    type="date"
                  ></input>

                </div>

                <div className="box-form__car-time">
                 
                <label
                    htmlFor="droptime"
                    style={{ color: "yellow" }}
                  >
                    Pick-up Time <b>*</b>
                  </label>
                  <input 
                    id="droptime"
                    value={pickingTime}
                    onChange={handleTimeChange}
                    type="time"
                    className="input-time"
                    style={{ width: "150px", height:'43px' }}
                  />
               
                </div>
                
                <div className="box-form__car-type">
                  <label style={{ color: "yellow" }}>
                    <i className="fa-solid fa-location-dot"></i> Your Email{" "}
                    <b>*</b>
                  </label>
                  <input  value={email} type="email"
                    onChange={handleEmail}
                    style={{ color: "#444443", height:'43px', paddingLeft:'10px' , fontSize:'14px' }}
                     placeholder="Enter Your Email Address"
                    >
                  </input>
                 
                </div>





                
              </form>

              <button
                onClick={sendEmail}
               // type="submit"
                style={{
                  alignSelf: "center",
                  width: "260px",
                  marginTop: "14px",
                  background: 'linear-gradient(175deg, #f7e9a0, #ffcc00, #d4af37, #b8860b)',
                  backgroundSize: '200% 200%',
                  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)',
                
                 // borderRadius: '10px',
                  color: 'white',
                  textAlign: 'center',
                  animation: 'shine 3s infinite linear',
                  //backgroundImage: 'linear-gradient(275deg, #00690a 0%, #3a9e0e 79%)',
                  //transition: "box-shadow 0.3s ease-in-out",

                  borderRadius: "33px",
                }}
              >
                Send Booking
              </button>
            </div>
          </div>
        </div>
      </section>

      {/* modal ------------------------------------ */}
      <div className={`booking-modal ${modal ? "active-modal" : ""}`}>
        {/* title */}
        <div
          className="booking-modal__title"
          style={{
            backgroundColor: "#5078f2",
            backgroundImage: "linear-gradient(315deg, #182b3a 0%, #20a4f3 74%)",
          }}
        >
          <h2>Complete Reservation</h2>
          <i onClick={openModal} className="fa-solid fa-xmark"></i>
        </div>
        {/* message */}
        <div className="booking-modal__message">
          <h4 style={{ color: "blue" }}>
            <i className="fa-solid fa-circle-info"></i> Upon completing this
            reservation enquiry:
          </h4>
          <p>
            Our staff will contact you at the earliest with confirmation
            details.
          </p>
        </div>
        {/* car info */}
        <div className="booking-modal__car-info">
          <div className="dates-div">
            <div className="booking-modal__car-info__dates">
              <h5 style={{ color: "blue" }}>Location & Date</h5>
              <span>
                <i className="fa-solid fa-location-dot"></i>
                <div>
                  <h6>Pick-Up Date & Time</h6>
                  <p>
                    {pickTime} / {formattedTimes}
                  </p>
                </div>
              </span>
            </div>
            <div className="booking-modal__car-info__dates">
              <span>
                <i className="fa-solid fa-location-dot"></i>
                <div>
                  <h6>Drop-Off Date & Time</h6>
                  <p>
                    {dropTime} / {formattedTimes1}
                  </p>
                </div>
              </span>
            </div>
            <div className="booking-modal__car-info__dates">
              <span>
                <i className="fa-solid fa-calendar-days"></i>
                <div>
                  <h6>Pick-Up Location</h6>
                  <p>{pickUp}</p>
                </div>
              </span>
            </div>
            <div className="booking-modal__car-info__dates">
              <span>
                <i className="fa-solid fa-calendar-days"></i>
                <div>
                  <h6>Drop-Off Location</h6>
                  <p>{dropOff}</p>
                </div>
              </span>
            </div>
          </div>
          <div className="booking-modal__car-info__model">
            <h5 style={{ color: "blue" }}>
              <span>Car -</span> {selectedCar}
            </h5>
            {carImgUrl && <img src={carImgUrl} alt="car_img" />}
          </div>
        </div>
        {/* personal info */}
        <div className="booking-modal__person-info" style={{}}>
          <h4 style={{ color: "blue" }}>Personal Information</h4>
          <form className="info-form">
            <div className="info-form__2col">
              <span>
                <label>
                  First Name <b>*</b>
                </label>
                <input
                  value={name}
                  onChange={handleName}
                  type="text"
                  placeholder="Enter full name"
                ></input>
                <p className="error-modal">This field is required.</p>
              </span>

              <span>
                <label>
                  Gender <b>*</b>
                </label>
                <input
                  value={lastName}
                  onChange={handleLastName}
                  type="text"
                  placeholder="Enter your gender"
                ></input>
                <p className="error-modal ">This field is required.</p>
              </span>

              <span>
                <div className="phoneInputContainer">
                  <label className="label">
                    Phone Number <b>*</b>
                  </label>
                  <div className="phoneInputWrapper">
                    <PhoneInput
                      international
                      defaultCountry="US"
                      value={phone}
                      onChange={setPhone}
                      placeholder="Enter your phone number"
                      className="phoneInput"
                    />
                  </div>
                  <p className="error-modal">This field is required.</p>
                </div>
              </span>

              <span>
                <label>
                  Age <b>*</b>
                </label>
                <input
                  value={age}
                  onChange={handleAge}
                  type="number"
                  placeholder="Enter age"
                ></input>
                <p className="error-modal ">This field is required.</p>
              </span>
            </div>
            <div className="info-form__1col">
              <span>
                <label>
                  Email <b>*</b>
                </label>
                <input
                  value={email}
                  onChange={handleEmail}
                  type="email"
                  placeholder="Enter your email address"
                ></input>
                <p className="error-modal">This field is required.</p>
              </span>

              
              <span>
                <label>
                  Address <b>*</b>
                </label>
                <input
                  value={address}
                 onChange={handleAddress}
                  type="text"
                  placeholder="Enter your address"
                ></input>
                <p className="error-modal ">This field is required.</p>
              </span>
              
            </div>

            <div className="info-form__2col">
              <span>
                <label>
                Have you obtained your drivers license?, If yes
                How long have you had it? <b>*</b>
                </label>
                <input
                  value={q1}
                  onChange={handleQ1}
                  type="text"
                  placeholder=" Enter yes or no, number of years"
                ></input>
                <p className="error-modal">This field is required.</p>
              </span>

              <span>
                <label>
                Have you been in any accident over the past 3 years?  <b>*</b>
                </label>
                <input
                  value={q2}
                  onChange={handleQ2}
                  type="text"
                  placeholder="Enter yes or no"
                ></input>
                <p className="error-modal">This field is required.</p>
              </span>
            </div>


            <div className="info-form__2col">
              <span>
                <label>
                 Is there adequate security for vehicle where it will be parked? <b>*</b>
                </label>
                <input
                  value={q3}
                  onChange={handleQ3}
                  type="text"
                  placeholder="Enter yes or no"
                ></input>
                <p className="error-modal">This field is required.</p>
              </span>

              <span>
                <label>
                Is this your first time renting a vehicle? if No,
How often do you book a rental car? <b>*</b>
                </label>
                <input
                  value={q4}
                  onChange={handleQ4}
                  type="text"
                  placeholder="Enter short answer"
                ></input>
                <p className="error-modal">This field is required.</p>
              </span>
            </div>


            <div className="info-form__2col">
            <span>
            <label>
                Upload driving license pictures, Front+Back <b>*</b>
                </label>
      {/* Input fields for booking data */}

      {/* Image selection and preview */}
      <input type="file" multiple accept="image/*" onChange={handleImageChange} />
      <div style={{ display: 'flex', gap: '10px', marginTop: '10px' }}>
        {imageURLs.map((url, index) => (
          <img key={index} src={url} alt={`Selected ${index}`} style={{ width: '100px', height: '100px', objectFit: 'cover' }} />
        ))}

      </div>
      </span>
      </div>

            <div className="reserve-button">
              <button
                onClick={confirmBooking}
                style={{
                  backgroundColor: "#182b3a",
                  backgroundImage:
                    "linear-gradient(315deg, #182b3a 0%, #20a4f3 74%)",
                }}
              >
                Reserve Now
              </button>

              <h4 style={{ textAlign: "center", marginTop: "30px" }}>
                Requirements & Terms
              </h4>
              <p style={{ fontSize: "13px" }}>
                We are 100% Locally Owned business, and we can work according to
                your requirements. We will need your driver's license picture to confirm your booking. We will assist you in
                any way possible. We take all major debit/credit cards You can pay us
                whenever you are ready. 
              </p>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default BookTransfer;
