import Footer from "../components/Footer";
import HeroPages from "../components/HeroPages";
import PlanTrip from "../components/PlanTrip";
import AboutMain from "../images/about/about-main.jpg";
import Box1 from "../images/about/icon1.png";
import Box2 from "../images/about/icon2.png";
import Box3 from "../images/about/icon3.png";
import BookTransfer from "../components/BookTransfer";
import { Helmet } from "react-helmet";

function Airport() {
  return (
    <>
      <Helmet>
        <title>Airport Transfers - Sleek Investments Car Rental</title>

        <meta
          name="description"
          content="Sleek Investments Car Rental provide reliable Montego Bay Airport Transfer & St Mary Airports Transfer service. Book for Norman Manley International Airport Transfer, Ian Fleming International Airport Transfer, Sangster International Airport Transfer."
        />
       
        {/* Add more meta tags as needed */}
      </Helmet>

      <section className="about-page">
        <HeroPages name="Airport Transfers" />

        <div style={{backgroundColor:'grey', textAlign:'center', padding:'10px'}}>
            <h2 style={{textAlign:'center', color:'white'}}>Book Airport Transfer</h2>
            
              <p style={{color:'gold', textAlign:'center', fontSize:'13px', paddingTop:'5px'}}>Sleek Investments Car Rental offers reliable airport transfer services across Jamaica, including Norman Manley International Airport in Kingston, Ian Fleming International Airport in Boscobel, and Sangster International Airport in Montego Bay. Whether you're arriving at Montego Bay Airport, St Mary Airport, or St Ann's Bay, our airport transportation ensures a smooth and comfortable journey to your destination. Trust our professional drivers for seamless transfers, making your travel experience in Jamaica stress-free and enjoyable.</p> 
           
          </div>

        <div style={{marginTop:'30px'}} >

      <BookTransfer/>


          {/*   <PlanTrip /> */}
         
        </div>
      </section>
      <div className="book-banner">
        <div className="book-banner__overlay"></div>
        <div className="container">
          <div className="text-content">
            <h2>For more information, we can be contact at</h2>
            <span>
              <i className="fa-solid fa-phone"></i>
              <h3 style={{color:'gold'}}> +1 (876)798-7720</h3> 
            </span>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Airport;
