import { Link } from "react-router-dom";
import BgShape from "../images/hero/Airport.jpg";
import BgShape2 from "../images/hero/Tour.jpg";

function Options() {
  const bgImageStyle = {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '93%',
    height: '100%',
    zIndex: 1,
    objectFit: 'cover',
  };

  const bannerContentStyle = {
    position: 'relative',
    zIndex: 2,
    backgroundColor: 'rgba(0, 0, 0, 0.59)',
   width: '93%',
    height: '100%',
   // display: 'flex',
   // alignItems: 'center',
   // justifyContent: 'center',
   textAlign:'center'
  };

  const colStyle = {
    position: 'relative',
    height: '350px',
    marginBottom: '20px', // To add spacing between stacked columns in mobile view
    display: 'flex',
    
    alignItems: 'center',
    justifyContent: 'center',
  };


  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };



  
  return (
    <>
      <section
        className="banner-section" id="options"
        style={{
          position: 'relative',
          overflow: 'hidden', marginTop:'-130px'
        }}
      >
        <div className="container">

          <div className="row">

            <div className="col-12 col-md-6" style={colStyle}>
            
            <img src={BgShape} alt="Background Shape" style={bgImageStyle} />
              
            <Link   to="/airport-transfers" style={{backgroundColor:'transparent'}} onClick={scrollToTop}  >
              <div className="banner-content" style={bannerContentStyle}>
             
                <div className="banner-content__text">
                  <h2>Airport Transfers</h2>
                  <p style={{ color: 'white' }}>
                   <span style={{ color: "yellow" }}>  Click Here For Booking</span>
                  </p>
                </div> 
                 </div>
                 </Link>

            </div>


            <div className="col-12 col-md-6" style={colStyle}>

            
            <img src={BgShape2} alt="Background Shape" style={bgImageStyle} />
              

            <Link  to="/tours" style={{backgroundColor:'transparent'}} onClick={scrollToTop}  >
              <div className="banner-content" style={bannerContentStyle}>
             
                <div className="banner-content__text">
                  <h2>Tours & Activities</h2>
                  <p style={{ color: 'white' }}>
                   <span style={{ color: "yellow" }}>  Click Here For Booking</span>
                  </p>
                </div>
                
                 </div>
                 </Link>

            </div>



          </div>
        </div>
      </section>
    </>
  );
}

export default Options;
